































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Robot } from "@/api/robot";
import { globalData } from "@/utils/config";
// @Component({
//   filters: {
//     dealStatusFilter(robotStat: any) {
//       if (robotStat == null || robotStat.battery_status == null) {
//         return "chargeValueRed";
//       } else if (robotStat.battery_status.power_supply_status == 1) {
//         return "chargeValueGreen";
//       } else if (robotStat.battery_status.percentage >= 70) {
//         return "chargeValue";
//       } else if (robotStat.battery_status.percentage >= 20) {
//         return "chargeValue";
//       } else if (robotStat.battery_status.percentage >= 10) {
//         return "chargeValue";
//       } else {
//         return "chargeValueRed";
//       }
//     },
//     dealIconFilter(robotStat: any) {
//       if (robotStat == null || robotStat.battery_status == null) {
//         return require("@/assets/img/b1.png");
//       } else if (robotStat.battery_status.power_supply_status == 1) {
//         return require("@/assets/img/charging.png");
//       } else if (robotStat.battery_status.percentage >= 90) {
//         return require("@/assets/img/b10.png");
//       } else if (robotStat.battery_status.percentage >= 80) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 70) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 60) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 50) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 40) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 30) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 20) {
//         return require("@/assets/img/b9.png");
//       } else if (robotStat.battery_status.percentage >= 10) {
//         return require("@/assets/img/b1.png");
//       } else {
//         return require("@/assets/img/b1.png");
//       }
//     },
//     dealBatteryIcon(robotStat: any) {
//       if (robotStat != null && robotStat.battery_status != null && robotStat.battery_status.current > 0) {
//         return require("@/assets/img/battery_charge.png");
//       } else {
//         return require("@/assets/img/battery.png");
//       }
//     },
//   },
// })
@Component({
  components: {},
})
export default class RobotBoard extends Vue {
  @Prop({
    type: Object,
    default: {
      name: "",
      robotId: "",
    },
  })
  private item!: Robot; //标题
  @Prop({
    type: Number,
    default: 0,
  })
  private index!: number; //显示返回按钮
  private startX: number = 0;
  private endX: number = 0;
  private startY: number = 0;
  private endY: number = 0;
  private isYjh: boolean = location.host == globalData.yjhUrl;
  @Prop({
    type: Number,
    default: 0,
  })
  private activeSwiper!: number; //当前活动的滑块

  get statusClass() {
    return this.dealStatusFilter(this.item.robotStat);
  }

  get iconSrc() {
    return this.dealIconFilter(this.item.robotStat);
  }

  get batteryIconSrc() {
    return this.dealBatteryIcon(this.item.robotStat);
  }
  get robotImage() {
    return this.item.robotEntity.robotType === 'YJH'
      ? require('@/assets/img/robot_img_yjh.png')
      : this.item.robotEntity.robotType === 'TC'
      ? require('@/assets/img/tc45/tc_45_robot.png'):require('@/assets/img/robot_img.png');
  }
  dealStatusFilter(robotStat: any) {
      if (robotStat == null || robotStat.battery_status == null) {
        return "chargeValueRed";
      } else if (robotStat.battery_status.power_supply_status == 1) {
        return "chargeValueGreen";
      } else if (robotStat.battery_status.percentage >= 70) {
        return "chargeValue";
      } else if (robotStat.battery_status.percentage >= 20) {
        return "chargeValue";
      } else if (robotStat.battery_status.percentage >= 10) {
        return "chargeValue";
      } else {
        return "chargeValueRed";
      }
    }
  dealIconFilter(robotStat: any) {
    if (robotStat == null || robotStat.battery_status == null) {
      return require("@/assets/img/b1.png");
    } else if (robotStat.battery_status.power_supply_status == 1) {
      return require("@/assets/img/charging.png");
    } else if (robotStat.battery_status.percentage >= 90) {
      return require("@/assets/img/b10.png");
    } else if (robotStat.battery_status.percentage >= 80) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 70) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 60) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 50) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 40) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 30) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 20) {
      return require("@/assets/img/b9.png");
    } else if (robotStat.battery_status.percentage >= 10) {
      return require("@/assets/img/b1.png");
    } else {
      return require("@/assets/img/b1.png");
    }
  }
  dealBatteryIcon(robotStat: any) {
    if (robotStat != null && robotStat.battery_status != null && robotStat.battery_status.current > 0) {
      return require("@/assets/img/battery_charge.png");
    } else {
      return require("@/assets/img/battery.png");
    }
}

  //跳转
  private skip(e: any) {
    if (this.checkSlide()) {
      this.setActiveSwiper(-1);
    } else {
      let index = e.currentTarget.dataset.index;
      this.$emit(
        "clickItem",
        this.item.robotEntity.robotId,
        this.item.robotEntity.name
      );
    }
  }

  private setActiveSwiper(index: number) {
    this.$emit("setActiveSwiper", index);
  }

  //滑动开始
  private touchStart(e: any) {
    this.startX = e.touches[0].clientX;
    this.startY = e.touches[0].clientY;
  }
  //滑动结束
  private touchEnd(e: any) {
    this.endX = e.changedTouches[0].clientX;
    this.endY = e.changedTouches[0].clientY;
    if (
      this.startX - this.endX > 30 &&
      this.startY - this.endY < 40 &&
      this.startY - this.endY > -40
    ) {
      this.setActiveSwiper(e.currentTarget.dataset.index);
    }

    if (this.startX - this.endX < -30) {
      this.setActiveSwiper(-1);
    }

    this.startX = 0;
    this.endX = 0;
    this.startY = 0;
    this.endY = 0;
  }
  //判断当前是否有滑块处于滑动状态
  private checkSlide() {
    return this.activeSwiper > -1;
  }
  //删除
  private deleteItem(e: any) {
    this.setActiveSwiper(-1);
    this.$emit("deleteItem", this.item.robotEntity.robotId);
  }

  //更新
  private updateItem(e: any) {
    this.setActiveSwiper(-1);
    this.$emit(
      "updateItem",
      this.item.robotEntity.robotId,
      this.item.robotEntity.name
    );
  }
}
